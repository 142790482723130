<template>
    <div class="lvya-appCenter">
        <div class="appCenter-box">
            <p class="appCenter-title">无需安装APP，各种应用&nbsp;&nbsp;&nbsp;&nbsp;随需而生</p>
            <div class="app-div">
                <ul class="tab-tilte">
                    <li v-for="(item,i) in listData" :key="i">
                        <p :class="{active:cur==i}" class="appCenter-left-p" @click="hrefUr3(i)">{{item.title}}</p>
                        <div class="appCenter-left-div" v-for="(list,v) in item.webAdminApps" :key="v" @click="hrefUr2(list.id)">
                            <img :src="'http://www.lvya.org/'+list.img" alt="" class="appCenter-left-img">
                            <p class="appCenter-left-p2">{{list.title}}</p>
                        </div>
                    </li>
                </ul>
                <div class="tab-content">
                    <div @click="cur=0"  v-show="cur==0">
                        <div>
                            <div class="appCenterDetails-right-top">
                                <div class="appCenterDetails-right-top-div1">
                                    <img :src="'http://www.lvya.org/'+details.img" alt="">
                                </div>
                                <div class="appCenterDetails-right-top-div2">
                                    <p>{{details.title}}</p>
                                    <p style="font-size:14px;color:#666;line-height: 23px;
                                    margin-top: 5px;">{{details.remark}}</p>
                                </div>
                                <div class="appCenterDetails-right-top-div3">
                                    <p style="background: #05b21a;cursor: pointer;" @click="hrefUrl1()">安装应用</p>
                                </div>
                            </div>
                            <h4 class="explain_title">应用说明</h4>
                            <div class="appCenterDetails-right-details" v-html="details.illustrate"></div>
                            <h4 class="explain_title">帮助说明</h4>
                            <div class="appCenterDetails-right-details" v-html="details.help"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import qs from 'qs'
export default {
    components: {
    },
    data() {
        return {
            cur: 0,
            listData: [],
            details: ''
        }
    },
    watch: {
        '$route'(to, from) {
            this.id = this.$route.query.id
            this.getId()
            this.getList()
            this.getDetails()
        }
    },
    methods: {
        getId() {
            this.id = this.$route.query.id
        },
        getList() {
            let origin = /localhost/.test(window.location.href) ? window.GateWayServer : location.origin
            axios.post(`${origin}/api/1/suite`, qs.stringify({
                pageNo: this.currentPage,
            }))
            .then(res => {
                this.listData = res.data.list
            })
        },
        getDetails() {
            let origin = /localhost/.test(window.location.href) ? window.GateWayServer : location.origin
            console.log(`${origin}/api/${this.id}` + '/app')
            axios.post(`${origin}/api/${this.id}` + '/app', qs.stringify({
                pageNo: this.currentPage,
            }))
            .then(res => {
                this.details = res.data.info
                this.imgUrl = res.data.info.help
                // document.write(this.imgUrl.replace(/website/g, 'http://www.lvya.org/website'))
                // console.log(this.imgUrl)
            })
        },
        hrefUrl1() {
            window.open('http://lvya.lvya.org', '_blank')
        },
        hrefUr2(id) {
            this.$router.push({ path: '/appCenterDetails', query: { id }})
        },
        hrefUr3(cur) {
            this.$router.push({ path: '/appCenter', query: { cur }})
        },
    },
    created() {
        this.getId()
        this.getList()
        this.getDetails()
    },
    mounted() {
    },
}
</script>

<style lang="less" scoped>
@import url("./less/style.less");
</style>
